import axios from 'axios'

const transport = axios.create({
  baseURL: '/',
  timeout: 5000,
  headers: {
    // 'Content-Type': 'multipart/form-data',
    // 'X-CSRF-TOKEN': '',
    // 'X-Requested-With': 'XMLHttpRequest'
  },
})

transport.interceptors.request.use(function(config){
  const meta = document.querySelector('[name="csrf-token"]')
  // console.log('token', meta.content, config)
  config.headers['X-CSRF-TOKEN'] = String(meta.content)
  // console.log('token', meta.content, config)
  return config
})

/**
 * отправка запроса на сервер
 *
 * @param formData {FormData} данные для отправки на сервер
 * @returns {Promise<AxiosResponse<T>>}
 */
export const submitOrder = function(formData){
  if (!formData) throw 'formData required!'
  return transport.post('/feedback.json', formData
    // for debug
    //{ authenticity_token: formData.get('authenticity_token'),
    //  order: {
    //    email: '',
    //    name: '',
    //    message: '',
    //    terms: false,
    //    phone: ''
    //  }
    //}
  );
}

export default {
  transport: transport,
  submitOrder: submitOrder
}