/**
 * @version 1.0.0
 */
import Vue from 'vue/dist/vue.esm'
import VeeValidate from 'vee-validate'

import {submitOrder} from './api'
import * as statuses from './statuses'
import rules from './rules'
import dict from './dict'

require('./_styles.sass')

Vue.use(VeeValidate, {locale: 'ru'})

export const FORM_ID = 'form_message'
export const FORM_REF = 'form_message'

/**
 * корневой контейнер формы
 * @type {string}
 */
export const APP_ID = 'form-message-container'
/**
 * название цели в яндекс метрика
 * @type {string}
 */
export const METRIKA_TARGET_NAME = 'form_message_sumbit_success'

/**
 * идентификатор метрики
 * @type {null}
 */
export const METRIKA_COUNTER = null

export const FormMessage = {
  el: `#${APP_ID}`,
  data() {
    return {
      /**
       * признак активного модального окна
       * изменяя, закрываем и открываем окно
       * (.modal.is-active)
       */
      modal: false,
      /**
       * локальный стек сообщений
       */
      flash: [],
      /**
       * Rails errors (validations)
       */
      railsErrors: null,
      /**
       * показывать ошибки, которые
       * возвратил сервер (обычно ошибки валидации)
       */
      showServerErrors: true,
      /**
       * признак работы
       */
      busy: false,
      /**
       * показывать дополнительные строки
       * помощи (p.help) под полями
       */
      showFieldHelp: true,
      /**
       * статус области
       */
      status: statuses.STATUS_PRISTINE,
      defaultMask: '+7 (9{3,4}) 9{4,7}',
      /**
       * модель формы
       */
      formData: {
        name: '',
        phone: '',
        email: '',
        message: '',
        terms: true
      },
      rules: rules
    }
  },
  created() {
    const vm = this
    window._eventbus_ = window._eventbus_ || new Vue()
    window._eventbus_.$on('modal:form-message', function () {
      vm.modal = true
    })

    /**
     * подключение словаря
     */
    this.$validator.localize('ru', dict)

    /**
     *  тестирование в ркжиме разработки
     */
    setTimeout(function () {
      // vm.submit()
    }, 1000)
  },
  computed: {
    isSuccess() {
      return this.status === statuses.STATUS_SUCCESS
    },
    isNotSuccess() {
      return this.status !== statuses.STATUS_SUCCESS
    },
    isError() {
      return this.status === statuses.STATUS_ERROR
    },
    isPristine() {
      return this.status === statuses.STATUS_PRISTINE
    },
    isDirty() {
      return this.status === statuses.STATUS_DIRTY
    }
  },
  methods: {
    submit() {
      const vm = this

      this.$validator.validateAll().then((result) => {
        if (!result) {
          vm.busy = false
          return
        }
        /**
         * terms always true
         */
        vm.formData.terms = true

        /**
         * доступ к форме можно получить
         *
         * 1) DOM: document.forms.<FORM_ID>
         * 2) DOM: document.getElementById(<FORM_ID>)
         * 3) Vue: vm.$refs.<FORM_REF>
         * 4) jQuery: $("form#<FORM_ID>")
         * 5) DOM: document.<FORM_ID>
         * 6) DOM: document.querySelector(#<FORM_ID>)
         *
         */
        let formData = new FormData(vm.$refs[`${FORM_REF}`])

        /**
         * ОБЯЗАТЕЛЬНО! Только после того
         * как данные формы получены через new FormData
         * иначе все поля будут пустыми. Почему?
         *
         * Только "правильные" активные поля формы
         * включаются в объект FormData, что означает, они
         * не должны быть заблокированы, а для select, radio, checkbox
         * выбраны значения
         *
         * Чтобы просмотреть данные FormData через console
         * можно использовать `console.log(Array.from(formData))`
         *
         * @type {boolean}
         */
        vm.busy = true

        /**
         * можно получить и использовать
         * ссылку из action атрибута формы,
         * но лучше использовать единый
         * согласованный API, выбрать что-то одно
         * или обычные формы Rails или приложения Vue
         */
        let action = vm.$refs[FORM_ID].action

        submitOrder(formData)
          .then(({data}) => {
            vm.status = statuses.STATUS_SUCCESS
            vm.$validator.errors.clear()
            vm.$validator.reset()

            /**
             * эксперимент
             * отправка событий в Метрику
             */
            if (window.ya && METRIKA_COUNTER) {
              window.ya(METRIKA_COUNTER, 'reachGoal', METRIKA_TARGET_NAME)
            }
          })
          .catch(({response: r, response: {status: s, data}}) => {
            // reset "old" errors
            this.railsErrors = null

            /**
             * @todo проверка статуса ответа (422,404,5xx,3xx)
             *
             * - 5xx - уведомление об ошибке сервера
             * - 422 - ошибки валидации или CSRF токена, ошибки обработки данных
             * - 3xx - неверная настройка
             * - 404 - неправильный URL
             */
            this.status = statuses.STATUS_ERROR

            /**
             * если статус ответа сервера не
             * соответсвует статусам ошибок,
             * предусмотренных приложением
             */
            if (!r || [404,422,500].indexOf(s) < 0) {
              console.warn(`нет ответа от сервера, status: ${s}`)
              return
            }

            switch (s) {
              case 422:
                /**
                 * ошибка валидации данных сервером
                 * это может быть ошибка валидации на стороне сервера,
                 * неправильный токен CSRF,
                 * ошибка обработки параметров в контроллере и т.д.
                 *
                 * При ошибке валидации Rails вернет объект с полями и описанием
                 * ошибок:
                 * {
                 *   field1: ['Ошибка...', 'Ошибка...'],
                 *   field2: ['Ошибка...']
                 * }
                 *
                 * Важно! При ошибке токена сервер возвратит статус 422,
                 * но формат ответа text/html. Если не проверить тип ответа,
                 * то можно получить ошибку при обработке запроса, если
                 * например использовать обход полей как массива или объекта.
                 *
                 * Нестандартные ошибки не должны возникать в
                 * процессе эксплуатации, например 404 или 5xx - этого
                 * в принципе не должно быть и пользователь не должен
                 * сталкиваться с подобными ситуациями. Пользователь
                 * не может влиять на подобные ошибки и нет необходимости
                 * рассказывать о таких ошибках. Достаточно попросить
                 * перезагрузить страницу. И правильнее всего отправить
                 * отчет об ошибке в Sentry.
                 *
                 */
                this.railsErrors = data
                this.errorMessage = ''
                break;
              case 500:
                /**
                 * ошибка на сервере
                 */
                this.errorMessage = ''
                console.warn('server returns 500')
                break;
              case 404:
                /**
                 * нет роута на который
                 * отправляется запрос
                 */
                this.errorMessage = ''
                console.warn('server returns 404')
                break;
              default:
                console.log('response error:default (not: 404,422,500)')
                break;
            }
          }).finally(() => {
          this.busy = false
        })
      })
    },
    /**
     * локальные сообщения
     */
    setFlash(msg) {
      this.flash.slice(0, this.flash.length)
      if (Array.isArray(msg)) {
        // replace array
        this.flash = msg
      } else {
        // add message
        this.flash.push(msg)
      }
    },
    clearFlashes() {
      this.flash.splice(0, this.flash.length)
    },
    cancelAndClose() {
      this.resetState()
      this.closeModal()
    },
    /**
     * глобальный сброс состояния
     * данные, статус, сообщения
     */
    resetState() {
      this.busy = false
      this.status = statuses.STATUS_PRISTINE

      this.formData.name = ''
      this.formData.email = ''
      this.formData.message = ''
      this.formData.phone = ''
      this.formData.terms = true

      this.clearFlashes()
      this.railsErrors = null
    },
    /**
     * кнопка закрыть окно
     */
    closeModal() {
      this.modal = false
      window._eventbus_.$emit('modal:form-message:close')

      this.errors.clear()
      this.$validator.reset()
      this.clearFlashes()
      this.railsErrors = null
    },
    /**
     * Кнопка "очистить" данные формы
     * @param fn
     */
    clearFormData(fn) {
      if (typeof fn !== 'function') {
        fn = function () {
        }
      }

      this.formData.name = ''
      this.formData.email = ''
      this.formData.phone = ''
      this.formData.message = ''
      this.formData.terms = true

      fn()
    },
    /**
     * обработка события клика на
     * кнопке закрыть общих уведомлений
     * об успехе или ошибке отправки запроса
     */
    notificationClose() {
      if (this.status === statuses.STATUS_ERROR) {
        this.status = statuses.STATUS_DIRTY
      } else if (this.status === statuses.STATUS_SUCCESS) {
        this.status = statuses.STATUS_PRISTINE
        this.modal = false
      } else {
        this.modal = false
      }
    }
  }
}
