import {FormMessage, APP_ID} from '../src/form-message/index'

document.addEventListener('DOMContentLoaded', function(){
  /**
   * global EventBus
   */
  window._eventbus_ = window._eventbus_ || new Vue()

  if (!document.getElementById(`${APP_ID}`)) {
    console.warn(`no element with id: #${APP_ID}`)
    return
  }

  /**
   * jQuery triggers
   */
  $('.button-js-form-message').click(function(){
    window._eventbus_.$emit('modal:form-message')
  })


  /**
   * создание приложения
   */
  new Vue(FormMessage)
})