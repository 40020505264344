export default {
  name: {
    required: true
  },
  email: {
    required: true,
    email: true
  },
  phone: {
    required: true
  },
  message: {
    required: true,
    max: 2000
  },
  terms: {
    required: true
  }
}