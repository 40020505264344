/**
 * сообщения об ошибках
 * валидации формы
 *
 * import messages from './messages
 * this.$validator.localize('ru', messages)
 */
const REQUIRED_FIELD = 'Обязательне поле'
/**
 * @return {string}
 */
const MAX_LENGTH = function(chars) {
  return `Пожалуйста, сообщение не должно содержать более ${chars || 2000} символов.`
}
const EMAIL_FORMAT = 'Неверный формат адреса электронной почты'

export default {
  custom: {
    email: {
      required: REQUIRED_FIELD,
      email: EMAIL_FORMAT
    },
    name: {
      required: REQUIRED_FIELD,
    },
    phone: {
      required: REQUIRED_FIELD
    },
    terms: {
      required: REQUIRED_FIELD
    },
    message: {
      required: REQUIRED_FIELD,
      max: MAX_LENGTH(2000)
    }
  }
}