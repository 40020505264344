/**
 * Форма отправлена успешно
 * @type {string}
 */
export const STATUS_SUCCESS = 'success'
/**
 * Форма отправлена, но ответ
 * от сервера содержит ошибку
 * @type {string}
 */
export const STATUS_ERROR = 'error'
/**
 * начальное состояние
 * @type {string}
 */
export const STATUS_PRISTINE = 'pristine'
/**
 * пользователь изменял данные формы
 * но успешной отправки не было
 * @type {string}
 */
export const STATUS_DIRTY = 'dirty'
/**
 * форма содержит ошибки валидации
 * (лучше использовать errors.any()
 * @type {string}
 */
export const STATUS_VALIDATE_ERROR = 'validate_error'

/**
 * в области выполняется какое-либо действие
 * @type {string}
 */
export const STATUS_BUSY = 'busy'